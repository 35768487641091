/* Import Geist font */
@import url('https://vercel.com/font/sans');
@import url('https://vercel.com/font/mono');

body {
  margin: 0;
  font-family: 'Geist', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'GeistMono', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Apply Geist font to all headings */
h1, h2, h3, h4, h5, h6 {
  font-family: 'Geist', sans-serif;
  font-weight: 600;
}

/* Apply Geist font to buttons and inputs */
button, input, select, textarea {
  font-family: 'Geist', sans-serif;
}

/* Ensure Geist is used for all text elements */
p, span, div, a {
  font-family: 'Geist', sans-serif;
}
