.action-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.9);
  color: var(--Neutral-60);
  font-size: 1.25rem;
  line-height: 1.75rem;
  text-transform: lowercase;
  cursor: pointer;
  animation: opacity-pulse 3s ease-in infinite;
  transition: all 0.2s ease-in-out;
  width: 48px;
  height: 48px;
  border-radius: 18px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  user-select: none;

  &:focus {
    border: 2px solid var(--Neutral-20);
    outline: 2px solid var(--Neutral-80);
  }

  &.outlined {
    background: rgba(255, 255, 255, 0.7);
    border: 1px solid rgba(255, 255, 255, 0.3);
  }

  .no-action {
    pointer-events: none;
  }

  &:hover {
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(255, 255, 255, 0.4);
  }

  &.connected {
    background: var(--Blue-800);
    color: var(--Blue-500);

    &:hover {
      border: 1px solid var(--Blue-500);
    }
  }

  &.connect-toggle {
    width: 56px;
    height: 56px;
    border-radius: 28px;

    .material-symbols-outlined {
      font-size: 24px;
    }
  }

  &:not(.connect-toggle) {
    &.disabled, .disabled & {
      opacity: 0.5;
      cursor: not-allowed;
      background: rgba(255, 255, 255, 0.5);
      color: var(--Neutral-30);
      pointer-events: none;
      animation: none;

      &:hover {
        background: rgba(255, 255, 255, 0.5);
        border: 1px solid rgba(255, 255, 255, 0.2);
      }
    }
  }
}

@property --volume {
  syntax: "length";
  inherit: false;
  initial-value: 0px;
}

.disabled .mic-button,
.mic-button.disabled {
  background-color: rgba(255, 255, 255, 0.2);
  color: var(--Neutral-30);
  cursor: not-allowed;
  pointer-events: none;
  border: 1px solid rgba(255, 255, 255, 0.1);

  &:before {
    display: none;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }

  .material-symbols-outlined {
    opacity: 0.7;
  }
}

.mic-button {
  position: relative;
  background-color: var(--accent-red);
  z-index: 1;
  color: white;
  transition: all 0.2s ease-in;

  &:focus {
    border: 2px solid var(--Neutral-20);
    outline: 2px solid var(--Red-500);
  }

  &:hover {
    background-color: var(--Red-400);
  }

  &:before {
    position: absolute;
    z-index: -1;
    top: calc(var(--volume) * -1);
    left: calc(var(--volume) * -1);
    display: block;
    content: "";
    opacity: 0.35;
    background-color: var(--Red-500);
    width: calc(100% + var(--volume) * 2);
    height: calc(100% + var(--volume) * 2);
    border-radius: 24px;
    transition: all 0.02s ease-in-out;
  }
}

.connect-toggle {
  &:focus {
    outline: none;
    border: none;
  }

  &:not(.connected) {
    background-color: #34C759;
    color: white;
    
    &:hover {
      background-color: #32B350;
    }
  }

  &.connected {
    background-color: #FF3B30;
    color: white;
    
    &:hover {
      background-color: #E6352B;
    }
  }
}

.control-tray {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  display: inline-flex;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  padding: 18px;
  width: 100%;
  z-index: 2;
  pointer-events: auto;
}

.actions-nav {
  background: rgba(250, 250, 250, 0.2);
  backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 27px;
  display: inline-flex;
  gap: 12px;
  align-items: center;
  overflow: clip;
  padding: 10px;
  transition: all 0.6s ease-in;

  & > * {
    display: flex;
    align-items: center;
  }

  .video-controls {
    display: flex;
    gap: 12px;
  }

  // Hide screen share and webcam buttons on mobile
  @media (max-width: 768px) {
    .video-controls {
      display: none;
    }
  }
}

@keyframes opacity-pulse {
  0% {
    opacity: 0.9;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.9;
  }
}

.audioPulse {
  display: flex;
  width: 24px;
  justify-content: space-evenly;
  align-items: center;
  transition: all 0.5s;

  & > div {
    background-color: var(--Neutral-30);
    border-radius: 1000px;
    width: 4px;
    min-height: 4px;
    border-radius: 1000px;
    transition: height 0.1s;
  }

  &.hover > div {
    animation: hover 1.4s infinite alternate ease-in-out;
  }

  height: 4px;
  transition: opacity 0.333s;

  &.active {
    opacity: 1;

    & > div {
      background-color: #0766AB;
    }
  }
}

.connection-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;

  .connection-button-container {
    border-radius: 27px;
    border: none !important;
    background: transparent !important;
    padding: 10px;
  }

  .text-indicator {
    font-size: 11px;
    color: white;
    user-select: none;
  }

  &:not(.connected) {
    .text-indicator {
      opacity: 0;
    }
  }
}
