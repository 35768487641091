.brand {
  font-size: 1.5rem;
  font-weight: 300;
  color: #000;
  display: flex;
  letter-spacing: 0.07rem;
  align-items: center;
  gap: 0.75rem;
  text-decoration: none;

  .audio-equalizer {
    display: flex;
    align-items: flex-end;
    gap: 2px;
    height: 16px;
    padding: 0 2px;

    .bar {
      width: 3px;
      background-color: #0766AB;
      border-radius: 1px;
      transform-origin: bottom;

      &.bar1 {
        height: 8px;
        animation: equalize1 1.6s ease-in-out infinite;
      }

      &.bar2 {
        height: 16px;
        animation: equalize2 1.6s ease-in-out infinite;
      }

      &.bar3 {
        height: 12px;
        animation: equalize3 1.6s ease-in-out infinite;
      }
    }
  }
}

@keyframes equalize1 {
  0%, 100% { transform: scaleY(0.5); }
  50% { transform: scaleY(1); }
}

@keyframes equalize2 {
  0%, 100% { transform: scaleY(1); }
  50% { transform: scaleY(0.5); }
}

@keyframes equalize3 {
  0%, 100% { transform: scaleY(0.75); }
  50% { transform: scaleY(0.25); }
} 