.wave-container { 
  height: 160px;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  background: transparent;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  overflow: visible;
  pointer-events: none;
  
  &.active {
    opacity: 1;
  }
}

.wave {
  position: absolute;
  bottom: -10px;
  width: 6400px;
  height: 160px;
  animation: wave 14s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
  transform: translate3d(0, 0, 0);
  opacity: 0.7;

  &:nth-of-type(2) {
    bottom: -8px;
    animation: wave 14s cubic-bezier(0.36, 0.45, 0.63, 0.53) -.25s infinite, swell 14s ease -1.5s infinite;
    opacity: 0.85;
  }

  &:nth-of-type(3) {
    bottom: -12px;
    animation: wave 14s cubic-bezier(0.36, 0.45, 0.63, 0.53) -.5s infinite, swell2 14s ease -1s infinite;
    opacity: 0.6;
  }
}

@keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1440px;
  }
}

@keyframes swell {
  0%, 100% {
    transform: translate3d(0,-3px,0);
  }
  50% {
    transform: translate3d(0,2px,0);
  }
}

@keyframes swell2 {
  0%, 100% {
    transform: translate3d(0,-2px,0);
  }
  50% {
    transform: translate3d(0,1px,0);
  }
}

// Audio responsive animation
.wave-container.active {
  .wave {
    animation-duration: 6s;
    
    &:nth-of-type(2) {
      animation-duration: 6s;
    }

    &:nth-of-type(3) {
      animation-duration: 6.5s;
    }
  }
} 