.privacy-policy {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  min-height: 100vh;
  background: var(--background-color);
  color: var(--text-color);

  .top-bar {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    gap: 1rem;

    .back-button {
      background: none;
      border: none;
      cursor: pointer;
      padding: 0.5rem;
      display: flex;
      align-items: center;
      color: var(--primary-color);
      transition: opacity 0.2s;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  &__container {
    background: var(--card-background);
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: var(--primary-color);
  }

  h2 {
    font-size: 1.8rem;
    margin: 2rem 0 1rem;
    color: var(--primary-color);
  }

  section {
    margin-bottom: 2rem;
  }

  p {
    line-height: 1.6;
    margin-bottom: 1rem;
  }

  ul {
    list-style-type: disc;
    margin-left: 2rem;
    margin-bottom: 1rem;

    li {
      margin-bottom: 0.5rem;
      line-height: 1.6;
    }
  }

  @media (max-width: 768px) {
    padding: 1rem;

    &__container {
      padding: 1rem;
    }

    h1 {
      font-size: 2rem;
    }

    h2 {
      font-size: 1.5rem;
    }
  }
} 