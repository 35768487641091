:root {
  --text: #333333;
  --gray-200: #e4e6e8;
  --gray-300: #c0c4c8;
  --gray-500: #8f9498;
  --gray-600: #6b7075;
  --gray-700: #4d5256;
  --gray-800: #2f3437;
  --gray-900: #1a1d1f;
  --gray-1000: #0a0c0e;
  --border-stroke: #e0e0e0;
  --accent-blue: #1f94ff;
  --accent-blue-active-bg: #e6f3ff;
  --accent-blue-active: #1f94ff;
  --accent-blue-headers: #0066cc;
  --accent-green: #34C759;

  --midnight-blue: #001233;
  --blue-30: #1f94ff;

  --accent-red: #ff4600;

  --background: #ffffff;
  --color: var(--text);

  scrollbar-color: var(--gray-300) var(--gray-200);
  scrollbar-width: thin;

  --font-family: "Space Mono", monospace;

  /* Neutral colors for light theme */
  --Neutral-00: #ffffff;
  --Neutral-5: #fafafa;
  --Neutral-10: #f5f5f5;
  --Neutral-15: #f0f0f0;
  --Neutral-20: #e6e6e6;
  --Neutral-30: #d4d4d4;
  --Neutral-50: #9e9e9e;
  --Neutral-60: #757575;
  --Neutral-80: #424242;
  --Neutral-90: #212121;

  --Green-500: #34C759;
  --Green-700: #248A3D;
  --Green-play: #34C759;

  --Blue-500: #1f94ff;
  --Blue-800: #e6f3ff;

  --Red-400: #ff9c7a;
  --Red-500: #ff4600;
  --Red-600: #e03c00;
  --Red-700: #bd3000;
}

body {
  font-family: "Space Mono", monospace;
  background: #ffffff;
  color: var(--text);
}

.material-symbols-outlined {
  &.filled {
    font-variation-settings:
      "FILL" 1,
      "wght" 400,
      "GRAD" 0,
      "opsz" 24;
  }
}

.space-mono-regular {
  font-family: "Space Mono", monospace;
  font-weight: 400;
  font-style: normal;
}

.space-mono-bold {
  font-family: "Space Mono", monospace;
  font-weight: 700;
  font-style: normal;
}

.space-mono-regular-italic {
  font-family: "Space Mono", monospace;
  font-weight: 400;
  font-style: italic;
}

.space-mono-bold-italic {
  font-family: "Space Mono", monospace;
  font-weight: 700;
  font-style: italic;
}

.hidden {
  display: none;
}

.flex {
  display: flex;
}

.h-screen-full {
  height: 100vh;
}

.w-screen-full {
  width: 100vw;
}

.flex-col {
  flex-direction: column;
}

@media (prefers-reduced-motion: no-preference) {}

.streaming-console {
  background: #ffffff;
  color: var(--gray-300);
  display: flex;
  height: calc(100vh - 64px);
  width: 100%;
  padding-top: 64px;
  position: relative;
  overflow: hidden;

  a,
  a:visited,
  a:active {
    color: var(--gray-300);
  }

  .disabled {
    pointer-events: none;

    >* {
      pointer-events: none;
    }
  }

  main {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 1rem;
  }

  .main-app-area {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(100% - 120px);
    overflow: hidden;
    position: relative;
  }

  .function-call {
    position: absolute;
    top: 0;
    width: 100%;
    height: 50%;
    overflow-y: auto;
  }

  .logo {
    position: absolute;
    top: 18px;
    left: 50%;
    transform: translateX(-50%);
    background: var(--Neutral-5);
    border: 1px solid var(--Neutral-30);
    border-radius: 27px;
    padding: 10px 20px;
    font-size: 1.1rem;
    color: var(--Neutral-90);
    z-index: 100;
  }
}

/* video player */
.stream {
  flex-grow: 1;
  max-width: 90%;
  border-radius: 32px;
  max-height: 100%;
  object-fit: contain;
}
