.app-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
  padding: 1.5rem 2rem;
  z-index: 1000;

  .header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    gap: 1rem;
    position: relative;
  }

  .auth-section {
    display: flex;
    align-items: center;
    gap: 1rem;

    &.desktop {
      @media (max-width: 768px) {
        display: none;
      }
    }
  }

  .user-info {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .avatar {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      object-fit: cover;
    }

    .email {
      color: #333333;
      font-size: 0.875rem;
    }
  }

  .logout-button {
    background-color: transparent;
    border: 1px solid #0766AB;
    color: #0766AB;
    padding: 0.5rem 1.5rem;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s;
    font-size: 0.875rem;
    min-width: 90px;
    min-height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    touch-action: manipulation;
    user-select: none;

    &:hover {
      background-color: #0766AB;
      color: #ffffff;
    }

    &:active {
      transform: scale(0.98);
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #0766AB;
    }
  }

  // Hamburger button
  .hamburger-button {
    display: none;
    background: none;
    border: none;
    cursor: pointer;
    padding: 8px;
    z-index: 1001;

    @media (max-width: 768px) {
      display: block;
    }
  }

  .hamburger {
    width: 24px;
    height: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    span {
      display: block;
      height: 2px;
      width: 100%;
      background-color: #333333;
      transition: all 0.3s ease;
      transform-origin: left center;
    }

    &.open {
      span:first-child {
        transform: rotate(45deg);
      }

      span:nth-child(2) {
        opacity: 0;
      }

      span:last-child {
        transform: rotate(-45deg);
      }
    }
  }

  // Mobile menu
  .mobile-menu {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    max-width: 300px;
    background-color: #ffffff;
    padding: 5rem 2rem 2rem;
    transform: translateX(100%);
    transition: transform 0.3s ease;
    z-index: 1000;
    box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);

    @media (max-width: 768px) {
      display: block;
    }

    &.open {
      transform: translateX(0);
    }

    .mobile-menu-content {
      padding-top: 4rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .user-info {
        padding: 1rem;
        background-color: rgba(7, 102, 171, 0.1);
        border-radius: 8px;
      }

      .logout-button {
        width: 100%;
      }
    }
  }

  // Overlay
  &::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
    z-index: 999;
  }

  &.menu-open::before {
    opacity: 1;
    visibility: visible;
  }
} 