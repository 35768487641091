.auth-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  background-color: #ffffff;
  position: relative;

  .top-bar {
    position: absolute;
    top: 1.5rem;
    left: 1.5rem;
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .auth-content {
    margin-top: 6rem;
    background: #ffffff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;

    h2 {
      text-align: center;
      margin-bottom: 1.5rem;
      color: #222222;
    }
  }
}

.back-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666666;
  transition: color 0.2s;
  border-radius: 50%;

  &:hover {
    color: #0766AB;
    background-color: rgba(7, 102, 171, 0.1);
  }

  .material-symbols-outlined {
    font-size: 24px;
  }
}

.form-group {
  margin-bottom: 1rem;

  input {
    width: 93%;
    padding: 0.75rem;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    font-size: 1rem;
    background-color: #ffffff;
    color: #333333;

    &::placeholder {
      color: #999999;
    }

    &:focus {
      outline: none;
      border-color: #0766AB;
      background-color: #ffffff;
    }
  }
}

.auth-button {
  width: 100%;
  padding: 0.75rem;
  background-color: #0766AB;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #065690;
  }
}

.google-button {
  width: 100%;
  padding: 0.75rem;
  background-color: #ffffff;
  color: #333333;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s;

  &:hover {
    background-color: #f5f5f5;
  }

  .google-icon {
    width: 18px;
    height: 18px;
    margin-right: 10px;
  }
}

.divider {
  margin: 1.5rem 0;
  text-align: center;
  position: relative;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    width: 45%;
    height: 1px;
    background-color: #e0e0e0;
  }

  &::before {
    left: 0;
  }

  &::after {
    right: 0;
  }

  span {
    background-color: #ffffff;
    padding: 0 10px;
    color: #666666;
    font-size: 0.9rem;
  }
}

.auth-toggle {
  margin-top: 1rem;
  text-align: center;
  color: #666666;

  button {
    background: none;
    border: none;
    color: #0766AB;
    cursor: pointer;
    padding: 0;
    font-size: inherit;
    text-decoration: underline;

    &:hover {
      color: #065690;
    }
  }
}

.error-message {
  color: #dc3545;
  margin-bottom: 1rem;
  text-align: center;
  font-size: 0.9rem;
}

.success-message {
  color: #28a745;
  margin-bottom: 1rem;
  text-align: center;
  font-size: 0.9rem;
} 