.landing-page {
  min-height: 100vh;
  background-color: #ffffff;
  color: #333333;

  .landing-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 2rem;
    max-width: 1200px;
    margin: 0 auto;
    position: relative;

    .nav-brand {
      display: flex;
      align-items: center;
    }

    .nav-links {
      display: flex;
      gap: 2rem;
      align-items: center;

      .nav-link {
        color: #333333;
        text-decoration: none;
        &:hover {
          color: #0766AB;
        }
      }

      .sign-in-btn {
        background-color: transparent;
        border: 1px solid #0766AB;
        color: #0766AB;
        padding: 0.5rem 1.5rem;
        border-radius: 4px;
        cursor: pointer;
        transition: all 0.2s;

        &:hover {
          background-color: #0766AB;
          color: #ffffff;
        }
      }

      &.desktop {
        @media (max-width: 768px) {
          display: none;
        }
      }
    }

    // Hamburger button
    .hamburger-button {
      display: none;
      background: none;
      border: none;
      cursor: pointer;
      padding: 8px;
      z-index: 1001;

      @media (max-width: 768px) {
        display: block;
      }
    }

    .hamburger {
      width: 24px;
      height: 20px;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      span {
        display: block;
        height: 2px;
        width: 100%;
        background-color: #333333;
        transition: all 0.3s ease;
        transform-origin: left center;
      }

      &.open {
        span:first-child {
          transform: rotate(45deg);
        }

        span:nth-child(2) {
          opacity: 0;
        }

        span:last-child {
          transform: rotate(-45deg);
        }
      }
    }

    // Mobile menu
    .mobile-menu {
      display: none;
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      max-width: 300px;
      background-color: #ffffff;
      padding: 5rem 2rem 2rem;
      transform: translateX(100%);
      transition: transform 0.3s ease;
      z-index: 1000;
      box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);

      @media (max-width: 768px) {
        display: block;
      }

      &.open {
        transform: translateX(0);
      }

      .mobile-menu-content {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;

        .nav-link {
          color: #333333;
          text-decoration: none;
          font-size: 1.1rem;
          padding: 0.5rem 0;
          
          &:hover {
            color: #0766AB;
          }
        }

        .sign-in-btn {
          background-color: transparent;
          border: 1px solid #0766AB;
          color: #0766AB;
          padding: 0.75rem 1.5rem;
          border-radius: 4px;
          cursor: pointer;
          transition: all 0.2s;
          width: 100%;
          margin-top: 1rem;

          &:hover {
            background-color: #0766AB;
            color: #ffffff;
          }
        }
      }
    }

    // Overlay
    &.menu-open::before {
      content: '';
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5);
      opacity: 1;
      visibility: visible;
      z-index: 999;
    }
  }

  .hero-section {
    display: flex;
    max-width: 1200px;
    margin: 2rem auto;
    padding: 0 2rem;
    gap: 4rem;
    align-items: center;

    .hero-content {
      flex: 1;

      h1 {
        font-size: 3.5rem;
        font-weight: bold;
        margin-bottom: 1.5rem;
        line-height: 1.2;
        color: #222222;
      }

      p {
        font-size: 1.25rem;
        color: #666666;
        margin-bottom: 2rem;
        line-height: 1.6;
      }

      .join-beta-btn {
        background-color: #0766AB;
        color: #ffffff;
        padding: 1rem 2rem;
        border: none;
        border-radius: 4px;
        font-size: 1.1rem;
        cursor: pointer;
        transition: all 0.2s;
        margin-bottom: 2rem;

        &:hover {
          background-color: #065690;
          transform: translateY(-2px);
        }
      }

      .example-queries {
        .query-item {
          color: #666666;
          margin-bottom: 1rem;
          font-size: 1.1rem;
          display: flex;
          align-items: center;
          gap: 0.75rem;

          .check-icon {
            color: #0766AB;
            font-size: 1.75rem;
            font-variation-settings:
              'FILL' 0,
              'wght' 400,
              'GRAD' 0,
              'opsz' 24;
          }
        }
      }
    }

    .hero-image {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        max-width: 100%;
        height: auto;
      }
    }
  }

  .landing-footer {
    padding: 1.5rem 2rem;
    background-color: #ffffff;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    margin-top: auto;

    .footer-content {
      max-width: 1200px;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      color: #666666;
      font-size: 0.875rem;

      .privacy-link {
        background: none;
        border: none;
        color: #0766AB;
        cursor: pointer;
        padding: 0;
        font-size: 0.875rem;
        text-decoration: none;
        transition: opacity 0.2s;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}

/* Responsive Design */
@media (max-width: 1024px) {
  .landing-page {
    .hero-section {
      flex-direction: column-reverse;
      text-align: center;
      gap: 2rem;

      .hero-content {
        h1 {
          font-size: 2.5rem;
        }

        .example-queries {
          text-align: left;
          max-width: 600px;
          margin: 0 auto;
        }
      }

      .hero-image {
        img {
          max-width: 80%;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .landing-page {
    .landing-nav {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      text-align: left;
      padding: 1rem;

      .nav-brand {
        margin: 0;
      }

      .hamburger-button {
        margin: 0;
      }
    }

    .hero-section {
      .hero-content {
        h1 {
          font-size: 2rem;
        }

        p {
          font-size: 1.1rem;
        }
      }
    }

    .landing-footer {
      padding: 1rem;

      .footer-content {
        flex-direction: column;
        gap: 0.5rem;
        text-align: center;
      }
    }
  }
}